import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import {
  FaEnvelopeOpenText,
  FaInstagram,
  FaTwitter,
} from 'react-icons/fa';

import Nav from './nav'
import { rhythm, scale } from '../utils/typography'
import '../styles/main.css'

const styles = {
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: rhythm(49),
    padding: `${rhythm(1)} ${rhythm(3/4)}`,
  },
  footer: {
    textAlign: 'center',
  },
  headerLogo: {
    marginBottom: 0,
    marginRight: rhythm(1/3),
  },
  headerTitle: {
    ...scale(1),
    margin: 0,
    padding: 0,
  },
  headerTitleLink: {
    boxShadow: 'none',
    color: 'inherit',
    display: 'flex',
    fontWeight: '900',
    textDecoration: 'none',
  },
  icons: {
    ...scale(1),
    boxShadow: 'none',
    marginRight: rhythm(1/2),
  },
}

function Container(props) {
  const { children } = props

  return (
    <div className="container" style={styles.container}>
      {children}
    </div>
  )
}

function Header(props) {
  const { index, title } = props
  const Title = index ? 'h1' : 'h3'

  return(
    <header className="site-header">
      <Container>
        <Link to={'/'} style={styles.headerTitleLink}>
          {/* <img src="https://via.placeholder.com/50" /> */}
          <img
            style={styles.headerLogo}
            src="/images/bend-logo.png"
            width="40"
            height="40"
          />
          {/* <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: '100%'
              }}
            /> */}
          <Title style={styles.headerTitle}>
            {title}
          </Title>
        </Link>
        <Nav />
      </Container>
    </header>
  )
}

function Content(props) {
  const { background, children } = props
  const styles = background && { background }

  return(
    <main style={styles}>
      <Container>
        {children}
      </Container>
    </main>
  )
}

function Footer(props) {
  return (
    <footer style={styles.footer}>
      <Container>
        <p>
          <a style={styles.icons} href="https://twitter.com/inbendco">
            <FaTwitter />
          </a>

          <a style={styles.icons} href="https://instagram.com/inbendco">
            <FaInstagram />
          </a>
        </p>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>As an Amazon Associate I earn from qualifying purchases</div>
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}></div>
          <div>a project by <a href="https://joshbuchea.com">josh buchea</a></div>
        </div>
      </Container>
    </footer>
  )
}

function handleClick(e) {
  const a = typeof e.target.closest === 'function' && e.target.closest('a')

  // if external link, open in new tab/window
  if (a && a.href && a.hostname !== window.location.hostname) {
    e.preventDefault()
    window.open(a.href, '_blank')
  }
}

function Layout(props) {
  const { children, contentBackground, location, title } = props
  const rootPath = `${__PATH_PREFIX__}/`
  const isIndex = location.pathname === rootPath

  return (
    <div onClick={handleClick}>
      <Header title={title} index={isIndex} />
      <Content background={contentBackground}>
        {children}
      </Content>
      <Footer />
    </div>
  )
}

export default Layout
