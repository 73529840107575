import Typography from 'typography'
import GitHub from 'typography-theme-github'

GitHub.overrideThemeStyles = () => ({
  'a': {
    boxShadow: '0 1px 0 0 #333',
    color: '#333',
    fontWeight: '500',
  },
  'a:hover,a:active': {
    boxShadow: 'unset',
    textDecoration: 'none',
  },
  'h1,h2,h3,h4,h5,h6': {
    borderBottom: 'none',
    marginTop: 0,
  },
  headerWeight: 700,
  'hr': {
    // marginTop: '1.75rem',
    marginTop: '.8125rem',
  },
})

const typography = new Typography(GitHub)

// import Wordpress2016 from 'typography-theme-wordpress-2016'
// Wordpress2016.overrideThemeStyles = () => ({
//   'h1,h2,h3,h4,h5,h6': {
//     marginTop: '1rem',
//   },
//   'ul,ol': {
//     marginLeft: '1.75rem',
//   },
//   'li': {
//     marginBottom: '.25rem',
//   },
//   // 'a.gatsby-resp-image-link': {
//   //   boxShadow: 'none',
//   // },
//   'a': {
//     color: '#333',
//   },
//   'hr': {
//     marginTop: '1.75rem',
//   },
// })
// delete Wordpress2016.googleFonts
// const typography = new Typography(Wordpress2016)

// const typography = new Typography({
//   baseFontSize: '18px',
//   baseLineHeight: 1.666,
//   headerFontFamily: ['Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
//   bodyFontFamily: ['Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
//   // See below for the full list of options.
// })

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

const { rhythm, scale } = typography;
export { rhythm, scale, typography as default };
