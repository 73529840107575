import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MailchimpForm from '../components/MailchimpForm'
import { rhythm } from '../utils/typography'

const styles = {
  content: {
    margin: '0 auto',
    maxWidth: '600px',
  },
}

class SiteIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        {/* <SEO title="All posts" keywords={['blog', 'gatsby', 'javascript', 'react']} /> */}
        {/* <h1>inBend</h1> */}
        <div style={styles.content}>
          <h2>Newsletter</h2>
          <p>Sign up for our monthly newsletter to receive announcements, news stories, and community information about beautiful Bend, Oregon</p>
          <MailchimpForm
            action="https://inbend.us20.list-manage.com/subscribe/post?u=e846729f5b7ae000c6c03b721&amp;id=20cbf78538"
            className="email-form"
          />
        </div>
      </Layout>
    )
  }
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
