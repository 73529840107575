import React from 'react'
import { Link } from 'gatsby'

import { rhythm } from '../utils/typography'

function Nav() {
  // const { author, social } = data.site.siteMetadata
  return (
    <nav
      className="site-nav"
      style={{
        // marginBottom: '3.5rem',
        // marginBottom: rhythm(1),
      }}
    >
      {/* <Link to={'/'}>Home</Link> */}
      {/* <Link to={'/'}>Home</Link> */}
      <Link to={'/gear/'}>Gear</Link>
      <Link to={'/traffic/'}>Traffic</Link>
      {/* <Link to={'/blog/'}>Blog</Link> */}
      {/* <Link to={'/about/'}>About</Link> */}
    </nav>
  )
}

export default Nav
